.App {
  text-align: center;
  background-color: white;
  width: 100vw;
  box-sizing: border-box;
}
div {
  box-sizing: border-box;
}

.App-logo {
  width: 100px;
  height: auto;
}

.select {
  font-size: 18px;
  border-radius: 12px !important;
  border: 1px solid #cbd5e1;
  background: #f8fafc;
  text-transform: capitalize;
}

.select:hover {
  border: 1px solid #e2f1e7;
}

.select > div {
  background-color: transparent !important;
  border-radius: 12px;
  padding: 8px !important;
}

.select input {
  background-color: transparent !important;
  border-radius: 12px;
}

.select fieldset {
  display: none !important;
}

.input {
  border-radius: 12px !important;
  border: 1px solid #cbd5e1 !important;
  background: #f8fafc;
}

.input:hover,
.input:focus,
.input:focus-within {
  border: 1px solid #e2f1e7 !important;
}

.input div {
  background-color: transparent !important;
  border-radius: 12px;
}

.input input {
  background-color: transparent !important;
  border-radius: 12px;
  padding: 8px !important;
}
.input input::placeholder {
  color: #9b9b9b;

  font-style: normal;
  font-weight: 400;
}
